<template>
  <div class="distribution-posters">
    <div>
      <cell-group>
        <cell title="姓名" :value="info.realname" />
        <cell title="ID" :value="info.id" />
        <cell title="手机号" :value="info.mobile" />
        <cell title="性别" :value="info.sex==1?'男':'女'" />
        <cell title="注册时间" :value="info.reg_time" />
        <cell title="邀请码" :value="info.invite_code" />
      </cell-group>
    </div>
    <div
      class="keep bg-color-red"
      v-clipboard:copy="info.invite_code"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >复制邀请码</div>
  </div>
</template>
<script>
import { Cell, CellGroup } from "vant";
import Generalize from "@api/generalize";
export default {
  name: "Poster",
  props: {},
  components: {
    Cell,
    CellGroup
  },
  data: function() {
    return {
      info: {},
      activeIndex: 0
    };
  },
  mounted: function() {
    this.invitecode();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    // 复制成功
    onCopy() {
      this.$toast("已复制到剪切板");
    },
    // 复制失败
    onError(e) {
      alert(e,"失败");
    },
    async invitecode() {
      try {
        const res = await Generalize.invite_code();
        this.info = res.data.info;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.distribution-posters {
  height: 100vh;
}
.distribution-posters .slider-banner {
  width: 100%;
  height: 500px;
  position: relative;
  margin-top: 20px;
}

.distribution-posters .slider-banner .swiper-slide {
  width: 300px !important;
  height: 100%;
}

.distribution-posters .slide-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.distribution-posters .keep {
  font-size: 15px;
  color: #fff;
  width: 300px;
  height: 40px;
  border-radius: 25px;
  text-align: center;
  line-height: 40px;
  margin: 18px auto;
  background-color: #1dbe5f !important;
  margin-top: 100px;
}
</style>